(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbAuth.service:Auth
   *
   * @description
   *
   */
  angular
    .module('wbAuth')
    .service('Auth', Auth);

  function Auth(LoggedUser, $http, Session, visor, $q, $cookies) {
    var self = this
      , activeSession;

    resetSession();

    this.login = function login(rawSession) {
      var auth = $q.defer();

      self.setSession(rawSession);

      activeSession.$save(auth.resolve, auth.reject);

      return auth
        .promise
        .then(unwrapSessionData)
        .then(setUserData)
        .then(loginUser);
    };

    this.adminLogin = function adminLogin(rawSession) {
      self.setSession(rawSession);

      return Session.adminLogin(activeSession).$promise
        .then(setUserData)
        .then(loginUser)
        .catch(function (response) {
          return $q.reject(response);
        });
    };

    this.silentLogin = function silentLogin(rawSession) {
      self.setSession(rawSession);

      return activeSession
        .$save()
        .then(unwrapSessionData)
        .then(function (sessionData) {
          self.setToken(sessionData.token);
        });
    };

    this.silentlogout = function silentlogout() {
      return activeSession
        .$delete()
        .then(function () {
          clearHeaderToken();
          activeSession = new Session();
        });
    };

    this.logout = function logout() {
      visor.setUnauthenticated();
      LoggedUser.empty();
      resetSession();
    };

    this.invalidateSession = function invalidateSession() {
      activeSession = null;
      this.logout();
    };

    this.setToken = function setToken(token) {
      activeSession.token = token;
      $http.defaults.headers.common.token = token;
    };

    this.getToken = function getToken() {
      return $cookies.get('loggedUserToken');
    };

    this.setSession = function setSession(rawSession) {
      activeSession.identifier = rawSession.identifier;
      activeSession.password = rawSession.password;
      activeSession.clientEmail = rawSession.clientEmail;
      activeSession.sessionTime = activeSession.session_time;
    };

    this.getSession = function getSession() {
      return activeSession;
    };

    function unwrapSessionData(response) {
      return response.data;
    }

    function setUserData(sessionData) {
      self.setToken(sessionData.token);
      return LoggedUser.setUserById(sessionData.userId);
    }

    function clearHeaderToken() {
      delete $http.defaults.headers.common.token;
    }

    function loginUser() {
      visor.setAuthenticated(LoggedUser.getUser());
    }

    function resetSession() {
      if (activeSession) {
        activeSession.$delete();
      }
      clearHeaderToken();
      activeSession = new Session();
    }
  }
}());
